"use client";

import * as React from "react";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
} from "@/components/shadcn/ui/navigation-menu";
import ThemeSwitcher from "@/components/uix/ThemeSwitcher/ThemeSwitcher";
import SocialNetwork from "@/components/uix/SocialNetwork/SocialNetwork";

const ProfilMenu = () => {
  return (
    <NavigationMenu className='text-primary'>
      <div className='mr-0 md:mr-2'>
        <SocialNetwork />
      </div>
      <NavigationMenuList>
        <NavigationMenuItem>
          <ThemeSwitcher />
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
};

export default ProfilMenu;
