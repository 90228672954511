"use client";

import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/shadcn/ui/navigation-menu";
import Logo from "@/components/uix/Logo/Logo";
import NavMobileMenu from "@/components/uix/NavMenu/NavMobileMenu";
import Link from "next/link";
import * as React from "react";
import { cn } from "@/lib/utils";
import GenerateUID from "@/utils/generateUID";

export const links: (
  | { type: "link"; title: string; href: string; description: string }
  | {
      type: "submenu";
      title: string;
      items: { href: string; title: string; description: string }[];
    }
)[] = [
  {
    type: "link",
    title: "Reprogrammation",
    href: "/reprogrammation-moteur",
    description: "Recherchez votre modèle de véhicule.",
  },
  {
    type: "link",
    title: "Tarifs",
    href: "/tarifs",
    description: "Nos Tarifs",
  },
  {
    type: "link",
    title: "Faq",
    href: "/faq",
    description: "Notre FAQ",
  },
  {
    type: "link",
    title: "Vidéos",
    href: "/videos",
    description: "Nos Vidéos",
  },
  {
    type: "link",
    title: "Contact",
    href: "/contact",
    description: "Prendre contact avec nous.",
  },
];

const NavMenu = () => {
  return (
    <div className='flex'>
      <NavigationMenu>
        <NavMobileMenu />
        <NavigationMenuList>
          <NavigationMenuItem>
            <Link href='/' legacyBehavior passHref>
              <NavigationMenuLink className='flex align-middle justify-center'>
                <div className='w-20 sm:w-full'>
                  <Logo width={120} />
                </div>
              </NavigationMenuLink>
            </Link>
          </NavigationMenuItem>
          {links.map((link, index) => {
            if (link.type === "link") {
              return (
                <NavigationMenuItem key={`${link.title}-${index}`} className='hidden md:flex'>
                  <Link href={link.href} aria-label={link.description} legacyBehavior passHref>
                    <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                      {link.title}
                    </NavigationMenuLink>
                  </Link>
                </NavigationMenuItem>
              );
            }
          })}
        </NavigationMenuList>
      </NavigationMenu>
      <NavigationMenu>
        <NavigationMenuList>
          {links.map((link, index) => {
            if (link.type === "submenu") {
              return (
                <NavigationMenuItem key={`${link.title}-${index}`} className='hidden md:flex'>
                  <NavigationMenuTrigger>{link.title}</NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <ul className='grid md:w-[250px] lg:w-[350px] lg:grid-cols-[1fr] p-2'>
                      {link.items.map((item, subIndex) => (
                        <ListItem key={`${GenerateUID()}`} href={item.href} title={item.title}>
                          {item.description}
                        </ListItem>
                      ))}
                    </ul>
                  </NavigationMenuContent>
                </NavigationMenuItem>
              );
            }
          })}
        </NavigationMenuList>
      </NavigationMenu>
    </div>
  );
};

const ListItem = React.forwardRef<React.ElementRef<"a">, React.ComponentPropsWithoutRef<"a">>(
  ({ className, title, children, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className,
            )}
            {...props}
          >
            <div className='text-sm font-medium leading-none'>{title}</div>
            <p className='line-clamp-2 text-sm leading-snug text-muted-foreground'>{children}</p>
          </a>
        </NavigationMenuLink>
      </li>
    );
  },
);
ListItem.displayName = "ListItem";

export default NavMenu;
