"use client";

import { FC, useState } from "react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/shadcn/ui/sheet";
import { links } from "@/components/uix/NavMenu/NavMenu";
import Link from "next/link";
import { GiHamburgerMenu } from "react-icons/gi";

const NavMobileMenu: FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleLinkClick = () => {
    setIsOpen(false); // Ferme le menu lorsque l'utilisateur clique sur un lien
  };

  return (
    <div className='flex md:hidden align-middle'>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <button aria-label='Toggle menu' onClick={() => setIsOpen(!isOpen)}>
            <GiHamburgerMenu size={20} className='ml-2 mr-4 text-primary' />
          </button>
        </SheetTrigger>
        <SheetContent side='top' className='flex align-middle justify-center'>
          <SheetHeader>
            <SheetTitle>RSTronic</SheetTitle>
            <SheetDescription>
              <nav className='flex flex-col text-center'>
                {links.map((link, index) => {
                  if (link.type === "link") {
                    return (
                      <Link
                        key={index}
                        href={link.href}
                        className='py-1 px-2'
                        onClick={handleLinkClick} // Appelle la fonction pour fermer le menu
                      >
                        {link.title}
                      </Link>
                    );
                  } else if (link.type === "submenu") {
                    return (
                      <div key={index} className='py-1 px-2'>
                        <span className='font-bold'>{link.title}</span>
                        <div className='flex flex-col pl-4'>
                          {link.items.map((item, subIndex) => (
                            <Link
                              key={subIndex}
                              href={item.href}
                              className='py-1'
                              onClick={handleLinkClick} // Ferme aussi pour les sous-menus
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
              </nav>
            </SheetDescription>
          </SheetHeader>
          <SheetFooter></SheetFooter>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default NavMobileMenu;
