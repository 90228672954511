"use client";

import LogoDark from "@/IMG/RS-LOGO-Dark.png";
import LogoLight from "@/IMG/RS-LOGO-Light.png";
import { useTheme } from "next-themes";
import Image from "next/image";
import React, { FC, useEffect, useState } from "react";
import styles from "./Logo.module.scss";
import { cn } from "@/lib/utils";

type TLogoProps = {
  width: number;
  className?: string;
};

const Logo: FC<TLogoProps> = ({ width, className }) => {
  const { theme, systemTheme } = useTheme();
  const [logoPath, setLogoPath] = useState(LogoDark);
  const [classNameLogoImage, setClassNameLogoImage] = useState(styles.logoImage);

  useEffect(() => {
    const currentTheme = theme === "system" ? systemTheme : theme;
    if (currentTheme === "dark") {
      setLogoPath(LogoLight);
    } else {
      setLogoPath(LogoDark);
    }
  }, [theme, systemTheme]);

  useEffect(() => {
    setClassNameLogoImage(`${styles.logoImage} ${styles[`width-${width}`]}`);
  }, [width]);

  return (
    <div className={cn(styles.logoContainer, className)}>
      <Image
        src={logoPath}
        alt='Logo RsTronic Martinique'
        width={0}
        height={0}
        className={cn(`flex gap-1 md:gap-2 w-20 h-auto`, styles.logoImage, classNameLogoImage)}
        priority
      />
    </div>
  );
};

export default Logo;
